<template>
  <section class="white-box mt-3">
    <div class="content">
      <nav class="header">
        <p class="main-title">Laudos</p>
        <router-link :to="goToMedicalReports" class="link">
          Ver laudos
        </router-link>
      </nav>
      <b-row>
        <b-col cols="4">
          <IconSimpleCard
            color="var(--states-error)"
            title="Expirado"
            :value="countExpiredMedicalReports"
            path="expirado"
            :onClick="redirectTo"
          >
            <template slot="icon">
              <File class="file-icon" />
            </template>
          </IconSimpleCard>
        </b-col>
        <b-col cols="4">
          <IconSimpleCard
            color="var(--orange)"
            title="A expirar"
            :value="countExpiringMedicalReports"
            path="aexpirar"
            :onClick="redirectTo"
          >
            <template slot="icon">
              <File class="file-icon" />
            </template>
          </IconSimpleCard>
        </b-col>
        <b-col cols="4">
          <IconSimpleCard
            color="var(--states-success)"
            title="Pendente"
            :value="countPendingMedicalReports"
            path="pendente"
            :onClick="redirectTo"
          >
            <template slot="icon">
              <File class="file-icon" />
            </template>
          </IconSimpleCard>
        </b-col>
      </b-row>
    </div>
  </section>
</template>
<script>
import { getCurrentUser, getCurrentClinic } from '@/utils/localStorageManager'
import moment from 'moment'

export default {
  name: 'MedicalReportsStatusResume',
  components: {
    IconSimpleCard: () => import('@/components/General/IconSimpleCard.vue'),
    File: () => import('@/assets/icons/file.svg')
  },
  data: () => ({
    user: getCurrentUser(),
    clinic: getCurrentClinic(),
    medicalReportsResume: {
      pending: [],
      expiring: [],
      expired: []
    },
    isLoading: true
  }),
  mounted() {
    this.isLoading = true
    this.isLoading = false
    this.getPendingMedicalReportsResume()
  },
  computed: {
    goToMedicalReports() {
      return '/laudos-medicos/laudos-pendentes?doctor=' + this.user.name
    },
    countPendingMedicalReports() {
      if (this.medicalReportsResume.pending) {
        return this.medicalReportsResume.pending.length
      }
      return 0
    },
    countExpiringMedicalReports() {
      if (this.medicalReportsResume.expiring) {
        return this.medicalReportsResume.expiring.length
      }
      return 0
    },
    countExpiredMedicalReports() {
      if (this.medicalReportsResume.expired) {
        return this.medicalReportsResume.expired.length
      }
      return 0
    }
  },
  methods: {
    getCurrentUser,
    getCurrentClinic,
    async getPendingMedicalReportsResume() {
      try {
        const res = await this.api.getPendingMedicalReportsResume(
          this.user?.id,
          this.clinic?.id
        )
        this.medicalReportsResume.pending = res.data.filter(
          report =>
            moment(report.limit_datetime)
              .startOf('day')
              .isAfter(moment().startOf('day'))
        )
        this.medicalReportsResume.expiring = res.data.filter(
          report =>
            moment(report.limit_datetime)
              .startOf('day')
              .isSame(moment().startOf('day'))
        )
        this.medicalReportsResume.expired = res.data.filter(
          report =>
            moment(report.limit_datetime)
            .startOf('day')
            .isBefore(moment().startOf('day'))
        )
      } catch (err) {
        this.medicalReportsResume = null
      }
    },
    redirectTo(path) {
      this.$router.push(
        '/laudos-medicos/laudos-pendentes?status=' +
          path +
          '&doctor=' +
          this.user.name
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.h-488 {
  height: 488px;
}

.link {
  font-weight: 700;

  &:hover {
    text-decoration: none !important;
  }
}

.messages {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.close-icon {
  width: 22px;
  height: 22px;
  fill: var(--states-error);
  background: #fee7e7;
  padding: 1px;
  border-radius: 8px;
}

.file-icon {
  stroke: var(--neutral-500);
  width: 24px;
  height: 24px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
